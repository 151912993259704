<template>
  <div>
    <div class="home">
      <GuestHeader
        :is-dark="false"
        :full-name="user.fullName"
        :email="user.email"
      />

      <div class="w-full">
        <div class="container">
          <div class="">
            <p class="tagline">{{ $t("contractGuest.tagline") }}</p>
            <p class="title mt-1">{{ $t("contractGuest.title") }}</p>
          </div>
          <div class="message mt-5" v-html="$t('contractGuest.transferMessage1', { lastName: lastName, firstName: firstName })"></div>
          <div class="message">{{ $t("contractGuest.transferMessage2") }}</div>

          <FlatButton
            @onClick="goNext"
            :text="$t('contractGuest.returnButton')"
            class="button-confirm mt-5 w-full"
          />
        </div>
      </div>
    </div>
    <Footer :white-footer="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import GuestHeader from "@/components/organisms/GuestHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { defineComponent, ref } from "vue";
import storage from "@/lib/storage/storageHelper";

export default defineComponent({
  name: "GuestTransferDone",
  components: {
    GuestHeader,
    Footer,
    FlatButton,
  },
  props: {
    lastName: { type: String, default: "" },
    firstName: { type: String, default: "" },
  },
  setup(props) {
    const localStorage = storage.getLocalStorage();
    const guestCode = localStorage.get("guestCode");
    const decode = Buffer.from(guestCode, "base64").toString();

    const user = JSON.parse(decode);
    user.fullName = user.lastName + " " + user.firstName;

    return { user };
  },
  methods: {
    goNext() {
      this.$router.push({ name: "ContractDetailReadonly" });
    },
  },
});
</script>

<style lang="scss" scoped>
.home {
  font-weight: 400;
  letter-spacing: 1px;
  min-height: calc(100vh - 60px);

  background: linear-gradient(-10deg, $placeholder1 50%, $secondary3 50%);

  @include mq(l) {
    padding: 0 10px;
    min-height: calc(100vh - 215px);
  }

  .container {
    @include flex(flex-start, center);
    padding: 50px;
    width: 600px;
    background-color: $white;
    border-radius: $containerRadius;
    box-shadow: 0 0 10px 3px rgba(2, 2, 2, 0.1);
    flex-direction: column;
    margin: calc(10%) auto 0;

    @include mq(l) {
      margin: 0;
      width: 100%;
      padding: 30px 20px;
      top: 10px;
      left: 0;
    }

    .tagline {
      @include fz(xs);
      @include fw(bold);
      color: $homeSectionTagLine;

      @include mq(l) {
        @include fz(xxs);
        line-height: 15px;
      }
    }

    .title {
      color: $secondary4;
      @include fz(xl);
      @include fw(bold);

      @include mq(l) {
        @include fz(s);
        margin-top: 15px;
        line-height: 20px;
      }
    }

    .message {
      display: flex;

      text-align: center;
      line-height: 28px;

      @include fz(s);
      @include fw(medium);
      color: $placeholder;

      @include mq(l) {
        @include fz(xs);
        line-height: 24px;
      }
    }

    .button-confirm {
      height: 60px;

      @include mq(l) {
        margin-top: 30px;
        height: 40px;
      }
    }
  }

  .m-auto {
    margin: auto;
  }

  .center {
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 300px);
  }
}
</style>
